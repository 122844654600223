// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyD16usIeyLUqzEB2EtDsQXe5mo7CWkJLpI",
//   authDomain: "zappybit.firebaseapp.com",
//   databaseURL: "https://zappybit.firebaseio.com",
//   projectId: "zappybit",
//   storageBucket: "zappybit.appspot.com",
//   messagingSenderId: "803280740898",
//   appId: "1:803280740898:web:25ffbc772d2b5022fae07b"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import { 
  collection, getDocs, doc, setDoc, updateDoc, addDoc, deleteDoc, 
  onSnapshot, query, where, increment, Timestamp, getDoc, orderBy, limit 
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// DHO account , Terratonepull-core production environment
// const configOptions = {
//     apiKey: "AIzaSyANKiI1YG5C82V4vAQYP3QN6rMrl2O8GCM",
//     authDomain: "local-story-275603.firebaseapp.com",
//     databaseURL: "https://local-story-275603.firebaseio.com",
//     projectId: "local-story-275603",
//     storageBucket: "local-story-275603.appspot.com",
//     messagingSenderId: "12478046088",
//     appId: "1:12478046088:web:a54c021bff5950b411a43d",
//     measurementId: "G-V9DSWXGQN8"
// };

// project nj for development

const configOptions = {
  apiKey: "AIzaSyBUKCQmgk-cBgT4cIYev3-rLuZdFee0AT8",
  authDomain: "tp-nj-4e83c.firebaseapp.com",
  databaseURL: "https://tp-nj-4e83c.firebaseio.com",
  projectId: "tp-nj-4e83c",
  storageBucket: "tp-nj-4e83c.appspot.com",
  messagingSenderId: "6772780528",
  appId: "1:6772780528:web:e9220c47279206f2b68135",
  measurementId: "G-EFQ7KNJ62J"
};

// The following fields are REQUIRED:
//  - Project ID
//  - App ID
//  - API Key
const secondaryAppConfig = {
  apiKey: "AIzaSyDODoKxSk3wGr9_iZmc71CHFiJPDJlezwA",
  authDomain: "terratonepull.firebaseapp.com",
  databaseURL: "https://terratonepull.firebaseio.com",
  projectId: "terratonepull",
  storageBucket: "terratonepull.appspot.com",
  appId: "1:850768789997:web:42cc8576deb634e9e5a7e4",
};



// project terratonepull for production
/*
const configOptions = {

apiKey: "AIzaSyDODoKxSk3wGr9_iZmc71CHFiJPDJlezwA",
authDomain: "terratonepull.firebaseapp.com",
databaseURL: "https://terratonepull.firebaseio.com",
projectId: "terratonepull",
storageBucket: "terratonepull.appspot.com",
messagingSenderId: "850768789997",
appId: "1:850768789997:web:42cc8576deb634e9e5a7e4",
measurementId: "G-FX8JD7SSC1"
};
*/

firebase.initializeApp(configOptions);
var firebase2=firebase.initializeApp(secondaryAppConfig,"secondary");

const storage2=firebase2.storage()
const db2=firebase2.firestore()

// firebase utils
const storage=firebase.storage()
const db = firebase.firestore()
const auth = getAuth()
const currentUser = auth.currentUser
const fbemail_ID=firebase.auth.EmailAuthProvider.PROVIDER_ID
// firebase collections
const usersCollection = db.collection('users')
// const postsCollection = db.collection('post').doc('perry').collection('posts')
// const commentsCollection = db.collection('comment').doc('perry').collection('comments')
// const likesCollection = db.collection('like').doc('perry').collection('likes')
// const commentlikesCollection = db.collection('commentlike').doc('perry').collection('commentlikes')


// const postsCollection= db.collection('teams').doc('yiBuVpolA9xDgUhFpn2V').collection('post').doc('P3a4iL7OmIYeRDAjirrx').collection('posts')
// const commentsCollection= db.collection('teams').doc('yiBuVpolA9xDgUhFpn2V').collection('comment').doc('P3a4iL7OmIYeRDAjirrx').collection('comments')
// const likesCollection= db.collection('teams').doc('yiBuVpolA9xDgUhFpn2V').collection('like').doc('P3a4iL7OmIYeRDAjirrx').collection('likes')
// const commentlikesCollection= db.collection('teams').doc('yiBuVpolA9xDgUhFpn2V').collection('commentlike').doc('P3a4iL7OmIYeRDAjirrx').collection('commentlikes')

const postsCollection= db.collection('posts')
const commentsCollection= db.collection('comments')
const likesCollection= db.collection('likes')
const commentlikesCollection= db.collection('commentlikes')



//const postsCollection = db.collection('posts')
//const commentsCollection = db.collection('comments')
//const likesCollection = db.collection('likes')
//const commentlikesCollection = db.collection('commentlikes')
// const corpsearchRef=db2.collection('corpsearch')
// const titlesearchRef=db2.collection('titlesearch')
const mergedlandsCollection = db.collection('mergedlands')

export const fbAuthStateListener = (callback) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      callback(user);
    } else {
      // User is signed out
      callback(null);
    }
  });
};

export const fbGetUserProfile = async () => {
  const user = auth.currentUser;
  console.log(user);

  const ref = doc(db, "users", user?.uid);
  const docSnap = await getDoc(ref);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return {
      ...docSnap.data(),
      uid: user?.uid,
    };
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!", user?.uid);
    return null;
  }
};

export {
  //auth:firebase.auth(),
  storage,
  db,
  auth,
  currentUser,
  usersCollection,
  postsCollection,
  commentsCollection,
  likesCollection,
  fbemail_ID,
  // corpsearchRef,
  // titlesearchRef,
  mergedlandsCollection,
  db2,
  storage2,
  firebase2,
  commentlikesCollection
}
