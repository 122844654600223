<template>
  <ion-row class="info-row">
  </ion-row>
  <ion-item lines="full">
    <ion-avatar slot="start" style="width: 60px; height: 60px">
      <img
        class="list-item__thumbnail"
        :src="
          post.userPhotoURL
            ? post.userPhotoURL
            : 'http://ui-avatars.com/api/?rounded=true&name=' + post.userName
        "
      />
      <span style="font-size: 0.7rem">{{ post.userName }}</span>
    </ion-avatar>
    <ion-label>
      <ion-row class="info-row">
        <h3 @click="oneClick(post.id)">
          <span v-if="post.centre">
            <location-tag
              :address="post.propinfo?.Address"
              :centre="post.centre"
            ></location-tag>
          </span>
        </h3>
        <ion-icon
          :icon="ellipsisVertical"
          @click="openActionSheet(post.id, post.content)"
        ></ion-icon>
      </ion-row>

      <h4 v-if="post.title">{{ post.title }}</h4>
      <p
        class="post_content"
        :data-raw="post.content"
        @click="viewPostComment(post)"
      >
        {{ post.content }}
      </p>

      <slot />
      <p>
        <span
          @click="displayUserLikesModal(post.id)"
          style="font-weight: bold"
          >{{ countDisplay(post.likes, "like") }}</span
        >
        .
        <span style="font-weight: bold">{{
          countDisplay(post.comments, "comment")
        }}</span>
        . <span>{{ formatDate(post?.createdOn) }}</span>
      </p>
    </ion-label>
  </ion-item>
</template>

<script setup>
import { computed, defineProps, defineEmits, ref } from "vue";
import { useRouter } from "vue-router";
import {
  IonThumbnail,
  IonAvatar,
  IonCheckbox,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonLabel,
  IonRadio,
  IonToggle,
  IonModal,
  IonContent,
  IonImg,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonRow,
  IonIcon,
  actionSheetController,
} from "@ionic/vue";
import {
  ellipsisVertical,
  trash,
  share,
  caretForwardCircle,
  heart,
  close,
} from "ionicons/icons";
import moment from "moment";
import LocationTag from "@/components/social-page/location-tag.vue";
import { useSocialStore } from "@/state";

const router = useRouter();

const props = defineProps(["post"]);
const emits = defineEmits(["displayUserPostLikesModal"]);

const socialStore = useSocialStore();

const viewPostComment = (post) => {
  socialStore.selectedPost = post;
  router.push({ name: "post-comment", params: { postId: post.id } });
};

const countDisplay = computed(() => (counts, strDisplay) => {
  if (!counts || counts == 0) {
    return `0 ${strDisplay}`;
  } else if (counts > 1) {
    return `${counts} ${strDisplay}s`;
  } else {
    return `${counts} ${strDisplay}`;
  }
});

const formatDate = computed(() => (val) => {
  if (!val) {
    return "-";
  }
  let date = val.toDate();
  return moment(date).fromNow();
});

const copy = (postContent) => {
  navigator.clipboard
    .writeText(postContent)
    .then(() => {
      alert("Post content is copied.");
      console.log("Text is on the clipboard.");
    })
    .catch((e) => {
      console.error(e);
    });
};

const openActionSheet = async (postId, content) => {
  let asButtons = [];

  if (socialStore.canPostBeDeleted(postId)) {
    asButtons.push({
      text: "Delete",
      role: "destructive",
      id: "delete-button",
      data: {
        type: "delete",
      },
      handler: () => {
        socialStore.softDeletePost(postId);
        console.log("Delete clicked");
      },
    });
  }

  asButtons.push(
    {
      text: "Share",
      data: 10,
      handler: () => {
        console.log("Share clicked");
      },
    },
    {
      text: "Copy",
      data: "Data value",
      handler: () => {
        copy(content);
        console.log("Copied");
      },
    },
    // {
    //   text: "Follow",
    //   handler: () => {
    //     console.log("Favorite clicked");
    //   },
    // },
    {
      text: "Cancel",
      role: "cancel",
      handler: () => {
        console.log("Cancel clicked");
      },
    }
  );
  const actionSheet = await actionSheetController.create({
    header: "Post Actions",
    cssClass: "my-custom-class",
    buttons: asButtons,
  });
  await actionSheet.present();
};

// const isLikesModalOpen = ref(false);
const displayUserLikesModal = (postId) => {
  // isLikesModalOpen.value = true;
  socialStore.fetchLikedUserList(postId);
  emits("displayUserPostLikesModal");
};
</script>

<style scoped>
.post_content {
  color: black;
  font-size: 1em;
  padding-top: 10px;
  padding-bottom: 10px;
  word-break: break-all;
  white-space: normal;
}

.info-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.info-row ion-icon:last-child {
  margin-left: auto;
}
</style>

