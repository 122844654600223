
// import { db } from "../firebase";
// import { 
//     collection, getDocs, doc, setDoc, updateDoc, addDoc, deleteDoc, 
//     onSnapshot, query, where, increment, Timestamp, getDoc, orderBy, limit 
// } from "firebase/firestore";
import _ from "lodash"
// import axios from 'axios';
// handle page reload

import { defineStore } from 'pinia'
import { db, storage, fbAuthStateListener, fbGetUserProfile } from "../firebase"
import { 
  collection, getDocs, doc, setDoc, updateDoc, addDoc, deleteDoc, 
  onSnapshot, query, where, increment, Timestamp, getDoc, orderBy, limit 
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, updateProfile } from "firebase/auth";



export const useAuthStore = defineStore('auth', {
    // arrow function recommended for full type inference
    state: () => {
      return {
        currentUser: {}, 
        profile:{}
      }
    },
    getters: {
        isAuthenticated: (state) => !_.isEmpty(state.currentUser)
    }, 
    actions: {
      initializeAuthListener() {
        return new Promise((resolve) => {
          fbAuthStateListener(async (user) => {
            this.currentUser = user ? user : null;
  
            if (user) {
              const profile = await fbGetUserProfile()
              console.log('profile', profile)
              this.profile = profile;
            }
            resolve(true);
          });
        });
      },

        async updateProfilePicture(imageFile) {
          
          let file=imageFile
          let extension=file.name.split('.').pop()
          let newfilename = this.currentUser.uid + "." + extension
          let newfile=new File([file],newfilename)
          console.log("newfilename",newfile.name)

          const storageRef = ref(storage, `images/${newfile.name}`);
          const uploadTask = uploadBytesResumable(storageRef, file);
          
          
          // Register three observers:
          // 1. 'state_changed' observer, called any time the state changes
          // 2. Error observer, called on failure
          // 3. Completion observer, called on successful completion
          uploadTask.on('state_changed', 
            (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
            }, 
            (error) => {
              // Handle unsuccessful uploads
              console.log("error upload profile image", error)
            }, 
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL);
                this.currentUser.photoURL=downloadURL
                const auth = getAuth();
                updateProfile(auth.currentUser, {
                  photoURL: this.currentUser.photoURL
                })
                await updateDoc(doc(db, "users", this.currentUser.uid), {
                  photoURL: this.currentUser.photoURL
                })
              });
            }
          );

        },
        async updateUsername() {
          console.log("updateUsername", this.currentUser.displayName )
          const auth = getAuth();
          updateProfile(auth.currentUser, {
            displayName: this.currentUser.displayName
          })
          
          await updateDoc(doc(db, "users", this.currentUser.uid), {
            displayName: this.currentUser.displayName
          })
          // update all posts by user to reflect new name
          // fb.postsCollection.where('userId', '==', state.currentUser.uid).get().then(docs => {
          //     docs.forEach(doc => {
          //         fb.postsCollection.doc(doc.id).update({
          //             userPhotoURL:newphotoURL
          //         })
          //     })
          // })
          // // update all comments by user to reflect new name
          // fb.commentsCollection.where('userId', '==', state.currentUser.uid).get().then(docs => {
          //     docs.forEach(doc => {
          //         fb.commentsCollection.doc(doc.id).update({
          //             userPhotoURL:newphotoURL
          //         })
          //     })
          // })
        }
    }
})