<template>
  <ion-item lines="none" button @click="showPortfolio = !showPortfolio" detail>
    <ion-label>{{
      showPortfolio ? "Hide Portfolio" : "Show Portfolio"
    }}</ion-label>
  </ion-item>
  <DataTable
    v-if="showPortfolio"
    :options="options"
    :data="ownerStore.getPortfolioTableData"
    class="display"
    width="100%"
  >
    <thead>
      <tr>
        <th>Address</th>
        <th>Asst Value</th>
        <th>LO</th>
      </tr>
    </thead>
  </DataTable>
</template>
<script setup>
import { IonItem, IonLabel } from "@ionic/vue";
import DataTable from "datatables.net-vue3";
import DataTableBs5 from "datatables.net-bs5";
import { ref } from "vue";
// import { DataTable,TableBody,TableHead, } from "@jobinsjp/vue3-datatable"
import { useOwnerStore } from "../../state";

// DataTable.use(DataTableBs5);
const showPortfolio = ref(false);
const ownerStore = useOwnerStore();

const options = {
  dom: "ltp",
};
</script>
<style scoped>
@import "datatables.net-dt";
</style>

