<template>
  <!-- <a @click="getBCA()"
    >BCA<font-awesome-icon icon="arrow-up-right-from-square"
  /></a> -->
  <ion-icon :icon="readerOutline" @click="getBCA()"></ion-icon>
  <ion-modal :is-open="isOpen">
    <ion-header>
      <ion-toolbar>
        <ion-title>PID List</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="dismiss()">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list>
        <ion-item
          v-for="item in pidList"
          :key="item[1]"
          @click="updateBCA(item[4])"
        >
          <ion-label>{{ item[2] }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-modal>
</template>
<script setup>
import {
  IonModal,
  IonList,
  IonButtons,
  IonButton,
  IonToolbar,
  IonTitle,
  IonHeader,
  IonContent,
  IonItem,
  IonLabel,
  toastController,
} from "@ionic/vue";

import { readerOutline } from "ionicons/icons";
import { useRouter } from "vue-router";

import { ref } from "vue";
import { useMapStore } from "@/state";
import axios from "axios";

const router = useRouter();
const mapStore = useMapStore();

const pidList = ref([]);
const isOpen = ref(false);
const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)

const getBCA = () => {
  if (mapStore.selectedParcel) {
    if (mapStore.selectedParcel.properties.PID !== null) {
      getBCAByPID();
    } else if (mapStore.selectedParcel.properties.PLAN_NUMBER !== null) {
      getBCAByPlan();
    }
  }
};

const getBCAByPlan = () => {
  if (mapStore.selectedParcel.properties.PLAN_NUMBER !== null) {
    var tokenURL =
      "https://www.bcassessment.ca/Property/Search/GetByPlan?plan=" +
      mapStore.selectedParcel.properties.PLAN_NUMBER;
    axios.get(proxyurl1 + tokenURL).then(async (response) => {
      console.log(response);
      if (response.data == "too_many_results") {
        const toast = await toastController.create({
          message: "Too many units found!",
          duration: 2000,
        });
        return toast.present();
      } else {
        pidList.value = response.data.aaData;
        isOpen.value = true;
        // console.log("pidList", that.pidList);
        // console.log("Total units", response.data.sEcho);
      }
      //that.showList=true
    });
  } else {
    alert("This parcel has no Plan Number provided for BC Assessment Search!");
  }
};

const getBCAByPID = () => {
  if (mapStore.selectedParcel.properties.PID !== null) {
    console.log(mapStore.selectedParcel.properties.PID);
    var tokenURL =
      "https://www1.bcassessment.ca/Property/Search/GetByPid/" +
      mapStore.selectedParcel.properties.PID;
    axios.get(proxyurl1 + tokenURL).then(function (response) {
      var softToken = response.data.aaData[0][4];
      sleep(1000);
      updateBCA(softToken);
    });
  } else {
    alert("This parcel has no PID provided for BC Assessment Search!");
  }

  function sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }
};

const updateBCA = (softToken) => {
  if (softToken) {
    return router.push({ name: "bca", params: { token: softToken } });
  }
};
</script>

<style scoped>
</style>