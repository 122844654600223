<template>
  <ion-grid>
    <ion-row class="ion-align-items-end"
    v-if="mapStore.selectedAddress != ''">
        <ion-col size="10">
          <ion-item lines="none">
            <location-tag
              :style="post.tagParcel ? 'color: blue' : 'color: grey'"
              :address="mapStore.selectedAddress"
            >
            </location-tag>
          </ion-item>
        </ion-col>
        <ion-col size="2">
          <div class="tag_parcel" lines="none">
            <ion-checkbox
              class="tagParcelcb"
              slot="start"
              @update:modelValue="post.tagParcel = $event"
              :modelValue="post.tagParcel"
            >
            </ion-checkbox>
            <!-- <ion-label>Tag</ion-label> -->
          </div>
        </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-end">
      <!-- <ion-col size="2">
         <ion-button color="light" @click="attachMedia">
          <ion-icon :icon="attachOutline" slot="icon-only"></ion-icon>
        </ion-button>
        <input
          id="fileUpload"
          type="file"
          ref="fileUpload"
          @change="fileUploadChange"
        /> 
      </ion-col> -->
      <ion-col size="12">
        <!-- <ion-item lines="none">
          <location-tag v-if="mapStore.selectedAddress != ''"
            :style="post.tagParcel ? 'color: grey' : 'color: blue'"
            :address="mapStore.selectedAddress"
          >
          </location-tag>
        </ion-item> -->
        <ion-item lines="none">
          <ion-input
            class="post-title"
            v-model.trim="post.title"
            placeholder="Title"
            inputmode="text"
            @ionFocus="focusInput($event)"
            @ionBlur="outInput($event)"
          ></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-input
            class="post-input"
            v-model.trim="post.content"
            inputmode="text"
            placeholder="Write a post ...."
            @ionFocus="focusInput($event)"
            @ionBlur="outInput($event)"
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-end">
      <!-- <ion-col size="4">
        <ion-item lines="none">
         <ion-button color="light" @click="attachMedia">
          <ion-icon :icon="attachOutline" slot="icon-only"></ion-icon>
        </ion-button>
        <input
          id="fileUpload"
          type="file"
          ref="fileUpload"
          @change="fileUploadChange"
        /> 
      </ion-item>
      </ion-col> -->
      <ion-col size="12">
      <ion-item lines="none">
        <ion-button
          color="primary"
          @click="createPost"
          :disabled="post.content === ''"
          style="width: 100%; height: 80%"
        >
        Add Post
          <!-- <ion-icon :icon="send" slot="icon-only"></ion-icon> -->
        </ion-button>
      </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- attached files -> open modal for media preview and continue type post
    <ion-modal ref="modal" :is-open="isOpen">
    <ion-header>
      <ion-toolbar>
        <ion-title>Comment Likes</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="isOpen = false">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list v-if="socialStore.commentLikes.length > 0">
        <ion-item
          v-for="(user, index) in socialStore.commentLikes"
          :key="index"
        >
          <ion-avatar slot="start">
            <ion-img :src="user.photoURL"></ion-img>
          </ion-avatar>
          <ion-label>
            <h2>{{ user.displayName }}</h2>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-label v-else>
        <h1>No Like Yet</h1>
      </ion-label>
    </ion-content>
  </ion-modal> -->
</template>

<script setup>
import { reactive, ref } from "vue";
import LocationTag from "@/components/social-page/location-tag.vue";

import { useSocialStore, useMapStore } from "@/state";
import { send, informationCircle, attachOutline } from "ionicons/icons";
import {
  IonTextarea,
  IonInput,
  IonLabel,
  IonIcon,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonCheckbox,
  toastController,
  onIonViewWillEnter,
  onIonViewDidEnter,
  onIonViewWillLeave,
  onIonViewDidLeave,
  modalController
} from "@ionic/vue";

const fileUpload = ref(null);

const mapStore = useMapStore();
const socialStore = useSocialStore();

const post = reactive({
  title: "",
  content: "",
  tagParcel: true,
});

const isOpen = ref(false);
const attachMedia = () => {
  // isOpen.value = true;
  fileUpload.value.click();
};

const outInput = (event) => {
  // to do
  // let container =  document.querySelector('.pane');
}
const focusInput = (event) => {
  // to do
  // // debugger
  //     let total = 0;
  //     let container = 'ion-content';

  //     const _rec = (obj) => {

  //         total += obj.offsetTop;
  //         const par = obj.offsetParent;
  //         if (par && par.localName !== 'ion-content') {
  //             _rec(par);
  //         } else {
  //             container = par;
  //         }
  //     };
  //     _rec(event.target);
  //     setTimeout(() => {
  //       container.scrollToPoint(0, total - 50, 400);
  //     }, 500);
}

const createPost = async () => {
  const postAdded = socialStore.addPost(post);

  const toast = await toastController.create({
    message: postAdded ? "Post sent." : "Post not sent",
    duration: 2000,
  });
  await toast.present();
};
</script>
<style scoped>
#fileUpload {
  display: none;
}

.ptextarea {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  width: 80%;
  resize: none;
}

.post-title {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: inherit;
  text-overflow: inherit;
  text-transform: inherit;
  text-align: inherit;
  white-space: inherit;
  color: inherit;
  border-radius: var(--border-radius);
  display: block;
  width: 100%;
  border: 0;
  outline: none;
  background: var(--background);
  font-family: inherit;
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.03);
  --border-radius: 10px;
  --box-shadow: none;
  --color: var(--ion-text-color, #000);
  --icon-color: var(--ion-color-step-600, #666666);
  height: 30px;
}
.post-input {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: inherit;
  text-overflow: inherit;
  text-transform: inherit;
  text-align: inherit;
  white-space: inherit;
  color: inherit;
  border-radius: var(--border-radius);
  display: block;
  width: 100%;
  border: 0;
  outline: none;
  background: var(--background);
  font-family: inherit;
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07);
  --border-radius: 10px;
  --box-shadow: none;
  --cancel-button-color: var(--ion-color-primary, #3880ff);
  --clear-button-color: var(--ion-color-step-600, #666666);
  --color: var(--ion-text-color, #000);
  --icon-color: var(--ion-color-step-600, #666666);

  padding-top: 12px;
  padding-bottom: 12px;
  height: 60px;
  contain: strict;
}
.tagParcelcb {
  --size: 18px;
}

.tag_parcel {
  margin-bottom: 0.65rem;
}
</style>
