<template>
  <div class="ion-padding-bottom">
    <ion-list>
      <ion-item>
        <ion-label>Year</ion-label>
        <ion-select
          interface="popover"
          @ionChange="(ev) => handleYearOnChange(ev.detail.value)"
          :value="mostRecentTransYear"
        >
          <ion-select-option v-for="year in years" :key="year" :value="year">{{
            year
          }}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item lines="none">
        <ion-label>Month</ion-label>
        <ion-text slot="end">{{ selectedMonthsDisplay }}</ion-text>
      </ion-item>
      <ion-item>
        <ion-range
          min="0"
          max="11"
          step="1"
          :value="selectedMonths"
          snaps="true"
          dual-knobs
          pin
          :pin-formatter="monthRangeFormatter"
          @ionKnobMoveEnd="(ev) => handleMonthsOnChange(ev.detail.value)"
        >
        </ion-range>
      </ion-item>
      <ion-item lines="none">
        <ion-label>Sold Price</ion-label>
        <ion-text slot="end">{{ priceRangeDisplay }}</ion-text>
      </ion-item>
      <ion-item>
        <ion-range
          min="0"
          :max="maxPrice"
          :step="500000"
          :value="selectedPrices"
          dual-knobs
          pin
          :pin-formatter="priceRangeFormatter"
          @ionChange="(ev) => handlePriceRangeOnChange(ev.detail.value)"
        >
        </ion-range>
      </ion-item>
      <ion-accordion-group :multiple="true">
        <ion-accordion value="assetTypes">
          <ion-item slot="header">
            <ion-label>Asset Types</ion-label>
          </ion-item>
          <ion-list slot="content">
            <ion-item v-for="type in recentTransAssetTypes" :key="type.id">
              <ion-label>{{ type.name }}</ion-label>
              <ion-checkbox
                slot="start"
                @ionChange="
                  (ev) => handleAssetTypeChange(ev.detail.checked, type)
                "
                :checked="type.isChecked"
              ></ion-checkbox>
            </ion-item>
          </ion-list>
        </ion-accordion>
        <ion-accordion value="saleTypes">
          <ion-item slot="header">
            <ion-label>Sale Types</ion-label>
          </ion-item>
          <ion-list slot="content">
            <ion-item
              v-for="type in recentTransSaleTypesSelections"
              :key="type.id"
            >
              <ion-label>{{ type.name }}</ion-label>
              <ion-checkbox
                slot="start"
                @ionChange="
                  (ev) => handleSaleTypeChange(ev.detail.checked, type)
                "
                :checked="type.isChecked"
              ></ion-checkbox>
            </ion-item>
          </ion-list>
        </ion-accordion>
      </ion-accordion-group>
      <ion-item lines="none">
        <ion-buttons>
          <ion-button @click="onApply">Apply</ion-button>
          <ion-button @click="onCancel">Cancel</ion-button>
          <ion-button color="danger" @click="clearLayer"
            >Clear Layer</ion-button
          >
        </ion-buttons>
      </ion-item>
    </ion-list>
  </div>
</template>


<script setup>
import {
  // IonicSlides,
  // IonSlides, IonSlide,
  // IonPage, IonModal,
  // IonContent,
  // IonThumbnail,
  // IonAvatar,
  IonCheckbox,
  // IonInput,
  IonItem,
  // IonItemOption,
  // IonItemOptions,
  // IonItemSliding,
  IonList,
  // IonRadio,
  // IonToggle,
  IonAccordion,
  IonAccordionGroup,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonText,
  IonPopover,
  IonDatetime,
  IonLabel,
  IonCard,
  IonCardTitle,
  IonButton,
  IonButtons,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  modalController,
  // onIonViewWillEnter, onIonViewDidEnter, onIonViewWillLeave, onIonViewDidLeave
} from "@ionic/vue";
import { computed, reactive, ref, defineEmits } from "vue";
import { format, parseISO, getDate, getMonth, getYear } from "date-fns";
import axios from "axios";
import _ from "lodash";
import * as olControl from "ol/control";
import * as olStyle from "ol/style";
import VectorLayer from "ol/layer/Vector";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import VectorSource from "ol/source/Vector";
import ClusterSource from "ol/source/Cluster";
import { fromLonLat } from "ol/proj";
import { useMapStore } from "@/state";

const mapStore = useMapStore();

let recentTrans = [];
const transDisplay = ref([]);
let recentTransCache = [];
// const isLocalDev = false;
// const isLocalDev =
//   location.hostname == "localhost" || location.hostname == "127.0.0.1";
// const realnetFileType = isLocalDev ? ".json" : ".json.gz";

const realnetFileType = ".json";

const years = ref([]);
const mostRecentTransYear = 2021;
const numTransYears = 5;
for (
  var transYear = mostRecentTransYear;
  transYear > mostRecentTransYear - numTransYears;
  transYear--
) {
  years.value.push(transYear);
}

const selectedYear = ref(mostRecentTransYear);

console.log;
axios
  .get(`/assets/data/RealnetOutput${mostRecentTransYear}${realnetFileType}`)
  .then((response) => {
    console.log("init load", mostRecentTransYear, response);
    if (response && response.status === 200) {
      recentTrans = response.data;
      recentTransCache.push({ transYear: recentTrans });
    }
  });

const handleYearOnChange = (year) => {
  if (year) {
    selectedYear.value = year;
    axios
      .get(`/assets/data/RealnetOutput${year}${realnetFileType}`)
      .then((response) => {
        console.log(year, response);
        if (response && response.status === 200) {
          recentTrans = response.data;
          recentTransCache.push({ transYear: recentTrans });
        }
      });
  }
};

const selectedMonths = reactive({
  lower: 0,
  upper: 2,
});

const selectedMonthsDisplay = computed(
  () =>
    `From ${monthRangeFormatter(selectedMonths.lower)} to ${monthRangeFormatter(
      selectedMonths.upper
    )}`
);
const handleMonthsOnChange = (data) => {
  selectedMonths.lower = data.lower;
  selectedMonths.upper = data.upper;

  // var dateStr = that.recentTrans[i].EventDate.substr(5,2);
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthRangeFormatter = (i) => monthNames[i];

const maxPrice = 100000000;
const selectedPrices = reactive({
  lower: 0,
  upper: 20000000,
});

const priceRangeDisplay = computed(
  () =>
    `From ${priceRangeFormatter(selectedPrices.lower)} to ${priceRangeFormatter(
      selectedPrices.upper
    )}`
);
const handlePriceRangeOnChange = (data) => {
  selectedPrices.lower = data.lower;
  selectedPrices.upper = data.upper;
};

const priceRangeFormatter = (p) =>
  `$${p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

const recentTransClusterDistance = 40;
const recentTransClusterRadius = 15;

const recentTransAssetTypes = reactive([
  {
    id: 0,
    code: "HOI",
    name: "Hotel",
    isChecked: true,
  },
  {
    id: 1,
    code: "ICI",
    name: "ICI Land",
    isChecked: true,
  },
  {
    id: 2,
    code: "IND",
    name: "Industrial",
    isChecked: true,
  },
  {
    id: 3,
    code: "APT",
    name: "Multi-Family",
    isChecked: true,
  },
  {
    id: 4,
    code: "OFF",
    name: "Office",
    isChecked: true,
  },
  {
    id: 5,
    code: "RET",
    name: "Retail",
    isChecked: true,
  },
  {
    id: 6,
    code: "RLN",
    name: "Residential Land",
    isChecked: true,
  },
]);

const handleAssetTypeChange = (isChecked, type) => {
  recentTransAssetTypes[type.id].isChecked = isChecked;
};

const recentTransSaleTypesSelections = reactive([
  {
    id: 0,
    name: "Market",
    isChecked: true,
  },
  {
    id: 1,
    name: "Non-Arms",
    isChecked: true,
  },
  {
    id: 2,
    name: "Share Sale",
    isChecked: true,
  },
  {
    id: 3,
    name: "Distress",
    isChecked: true,
  },
  {
    id: 4,
    name: "Business Transaction",
    isChecked: true,
  },
  {
    id: 5,
    name: "Right To Purchase",
    isChecked: true,
  },
  {
    id: 6,
    name: "Others",
    isChecked: true,
  },
]);

const handleSaleTypeChange = (isChecked, type) => {
  recentTransSaleTypesSelections[type.id].isChecked = isChecked;
};

const featureTypeMarker = "transactionMarker";
const featureTypeCluster = "transactionCluster";

var markerImage = new olStyle.Icon({
  anchor: [0.5, 1],
  crossOrigin: "anonymous",
  scale: 0.1,
  src: "/assets/img/home-map-marker.png",
});
var clusterImage = new olStyle.Circle({
  radius: recentTransClusterRadius,
  stroke: new olStyle.Stroke({ color: "#fff" }),
  fill: new olStyle.Fill({ color: "#0099e6" }),
});
var clusterTextFill = new olStyle.Fill({ color: "#fff" });

// Create the Recent Transaction Cluster Layer
const recentTransLayer = new VectorLayer({
  title: "Recent Transaction",
  name: "recentTrans",
  visible: false,
  style: function (feature) {
    var size = feature.get("features").length;
    var style;
    if (size == 1) {
      feature.featureType = featureTypeMarker;
      style = new olStyle.Style({
        image: markerImage,
      });
    } else {
      feature.featureType = featureTypeCluster;
      style = new olStyle.Style({
        image: clusterImage,
        text: new olStyle.Text({
          text: size.toString(),
          fill: clusterTextFill,
        }),
      });
    }
    return style;
  },
});
recentTransLayer.setZIndex(1);

const formatDate = (value, dateType) => {
  switch (value) {
    case "year":
      return format(parseISO(value), "yyyy");
    case "month":
      return format(parseISO(value), "MMM");
    default:
      return "";
  }
  // return format(parseISO(value), 'MMM yyyy')
};

const emit = defineEmits(["onApply", "onCancel", "onClearLayer"]);

const onApply = () => {
  var markers = [];
  transDisplay.value = recentTrans;
  transDisplay.value = transDisplay.value
    .filter((tran) => {
      var found = false;
      found =
        getMonth(parseISO(tran.EventDate)) >= selectedMonths.lower &&
        getMonth(parseISO(tran.EventDate)) <= selectedMonths.upper &&
        tran.Price >= selectedPrices.lower &&
        tran.Price <= selectedPrices.upper;

      if (!found) return false;

      if (recentTransAssetTypes.findIndex((i) => !i.isChecked) != -1) {
        recentTransAssetTypes.forEach((type) => {
          if (type.isChecked && tran.AssetType === type.code) {
            found = true;
            return;
          }
        });
      }

      if (!found) return false;

      if (recentTransSaleTypesSelections.findIndex((i) => !i.isChecked) != -1) {
        let saletype =
          tran.SaleType == null ? "Others" : tran.SaleType.split(" - ")[0];
        recentTransSaleTypesSelections.forEach((type) => {
          if (type.isChecked && saletype === type.name) {
            found = true;
            return;
          }
        });
      }

      return found;
    })
    .map((filteredResult, i) => {
      let marker = new Feature({
        geometry: new Point(
          fromLonLat([filteredResult.Longitude, filteredResult.Latitude])
        ),
        index: i,
      });
      markers.push(marker);
      return filteredResult;
    });

  mapStore.filteredTransactions = transDisplay.value;

  let recentTransClusterSource = new ClusterSource({
    distance: recentTransClusterDistance,
    source: new VectorSource({ features: markers }),
  });
  recentTransLayer.setSource(recentTransClusterSource);

  mapStore.map.addLayer(recentTransLayer);
  emit("onApply");
};
const onCancel = () => emit("onCancel");
const clearLayer = () => emit("onClearLayer");
</script>
<style scoped>
/* ion-modal.propertyModal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  background: #777;
  --border-color: #ABC098;
}

ion-modal.ownerModal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  background: #999;
  --border-color: #DEF098
} */
</style>