<template id="sheet">
  <ion-list-header style="border-bottom: 1px solid grey">
    <ion-label style="margin-top: 10px">{{!isShowPostList ? 'Add Post' : 'Post List'}}</ion-label>
    <ion-button @click="showAddPost()" v-if="isShowPostList">Add Post</ion-button>
    <ion-button @click="showPostList()" v-if="!isShowPostList">Show Post</ion-button>
  </ion-list-header>
  <div id="sheet-modal-content" style="overflow-y: auto !important;">
    <post-list v-if="isShowPostList" :posts="socialStore.filteredPostsByGeoHash" />

    <create-post v-if="!isShowPostList" />
    <ion-item
      v-if="isShowPostList"
      class="sheet-last-item"
      lines="full"
      @click="router.push({ name: 'post' })"
    >
      <ion-text color="primary">See all Posts</ion-text>
    </ion-item>
    <ion-row></ion-row>
  <!-- </ion-content> -->
  </div>
</template>


<script setup>
import { IonText, IonListHeader, IonItem } from "@ionic/vue";
import { useRouter } from "vue-router";
import { useSocialStore } from "@/state";
import { ref } from "vue";

import PostList from "@/components/social-page/post-list.vue";
import CreatePost from "@/components/sheet-modal/create-post.vue";

const router = useRouter();
const socialStore = useSocialStore();
const isShowPostList = ref(true);

const showAddPost = () => {
  isShowPostList.value = false;
}

const showPostList = () => {
   isShowPostList.value = true;
}
// const emit = defineEmits(["toggleLandMergerMode", "cancelLandMergerMode"]);
</script>
<style scoped>
.sheet-last-item {
  margin-bottom: 51px;
}
/* ion-modal.ownerModal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

ion-modal {
  --height: 50%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-modal ion-toolbar {
  --background: rgb(14 116 144);
  --color: white;
} */

/* ion-modal.propertyModal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  background: #777;
  --border-color: #ABC098;
}

ion-modal.ownerModal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  background: #999;
  --border-color: #DEF098
} */
</style>