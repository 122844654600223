import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
import { createPinia } from 'pinia'
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileInvoiceDollar, faStreetView, faArrowUpRightFromSquare, faSatellite, faObjectGroup, faObjectUngroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { useAuthStore } from "./router/useAuthStore"

library.add(faFileInvoiceDollar, faStreetView, faArrowUpRightFromSquare, faSatellite, faObjectGroup, faObjectUngroup)

const pinia = createPinia()
const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon)
  .use(IonicVue)
  .use(pinia);
const authStore = useAuthStore()

authStore.initializeAuthListener().then(() => {
  app.use(router).mount("#app");
});