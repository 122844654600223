
<template>
  <ion-page>
    <ion-header collapse="condense">
      <ion-toolbar>
        <ion-title size="large">Profile</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card>
        <ion-item
          detail
          class="userItem"
          lines="full"
          @click="router.push({ name: 'updateProfile' })"
        >
          <ion-avatar slot="start" style="width: 80px; height: 80px">
            <img :src="authStore.currentUser.photoURL" />
          </ion-avatar>
          <ion-label>{{ authStore.currentUser.displayName }}</ion-label>
        </ion-item>
        <ion-item>
          <ion-label>{{ authStore.currentUser.email }}</ion-label>
        </ion-item>

        <!-- <div style="display:table;margin:0 auto;">
            
            <input
              id="elemFileUpload"
              type="file"
              class="form-control"
              @change="fileUploadChange"
            >
          </div> -->
      </ion-card>

      <ion-card>
        <ion-item
          detail
          class="settingItem"
          lines="full"
          @click="router.push({ name: 'bookmarks' })"
        >
          <ion-label>Manage Bookmarks</ion-label>
        </ion-item>
        <ion-item button @click="showAboutAlert()">
          <ion-label> About </ion-label>
        </ion-item>
      </ion-card>

      <ion-card>
        <ion-item button detail="false" color="danger" @click="handleLogout()">
          Logout
        </ion-item>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script setup>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonAvatar,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  alertController,
} from "@ionic/vue";

import { useRouter } from "vue-router";
import { useAuthStore } from "../../router/useAuthStore";
import { getAuth } from "firebase/auth";

const router = useRouter();
const authStore = useAuthStore();
const auth = getAuth();

const showAboutAlert = async () => {
  const alert = await alertController.create({
    header: "About this app",
    subHeader: "TerratonePull",
    message: "TerratonePull Version 3.0.1-beta",
    buttons: ["OK"],
  });
  await alert.present();
};

const handleLogout = async () => {
  // router.replace({ name: "login" });
  await auth.signOut();

  window.location.replace("/login");
};
</script>
<style scoped>
ion-item.userItem {
  --min-height: 100px;
}
</style>