<template>
  <ion-page>
    <ion-content>
      <div id="firebaseui-auth-container" />
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent } from "@ionic/vue";

import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "../../node_modules/firebaseui/dist/firebaseui.css";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { useAuthStore } from "../router/useAuthStore";

const router = useRouter();
const authStore = useAuthStore();

onMounted(() => {
  if (!authStore.isAuthenticated) {
    let uiConfig = {
      signInOptions: [
        {
          signInSuccessURL: "/tabs/map",
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        },
      ],
      callbacks: {
        signInSuccessWithAuthResult(authResult, redirectUrl) {
          console.log(
            "signInSuccessWithAuthResult",
            authResult,
            redirectUrl,
            firebase.auth().currentUser
          );
          //localStorage.setItem('authenticated', true)
          console.log("router", router);
          router.push({ name: "map" });
          // return true
        },
      },
    };
    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", uiConfig);
  } else {
    router.push({ name: "map" });
  }
});
</script>