<template>
  <div
    v-show="Object.prototype.hasOwnProperty.call(ownerStore.owner, 'Address')"
  >
    <h1 @click="goToSearch2FN1">
      <a>{{ FullName1 }}</a>
      <ion-icon slot="icon-only" :icon="search"></ion-icon>
    </h1>
    <h2 v-if="FullName2 != ''" @click="goToSearch2FN2">
      <a>{{ FullName2 }}</a>
      <ion-icon slot="icon-only" :icon="search"></ion-icon>
    </h2>

    <h3 v-if="ownerStore.owner.Company != ''" @click="goToSearch2CY">
      <a>{{ ownerStore.owner.Company }}</a>
    </h3>
    <br />
    <h3 v-if="ownerStore.owner.Address != ''">
      {{ ownerStore.owner.Address }}
    </h3>

    <ion-row v-if="ownerStore.owner.ContactNo != ''">
      <ion-col>Office: </ion-col>
      <ion-col
        ><a :href="'tel:' + ownerStore.owner.ContactNo">{{
          ownerStore.owner.ContactNo
        }}</a></ion-col
      >
    </ion-row>
    <ion-row v-if="ownerStore.owner.MobileTel != ''">
      <ion-col>Mobile: </ion-col>
      <ion-col
        ><a :href="'tel:' + ownerStore.owner.MobileTel">{{
          ownerStore.owner.MobileTel
        }}</a></ion-col
      >
    </ion-row>
    <ion-row v-if="ownerStore.owner.HomeTel != ''">
      <ion-col>Home: </ion-col>
      <ion-col
        ><a :href="'tel:' + ownerStore.owner.HomeTel">{{
          ownerStore.owner.HomeTel
        }}</a></ion-col
      >
    </ion-row>
    <ion-row v-if="ownerStore.owner.Email_key3 != ''">
      <ion-col>Email: </ion-col>
      <ion-col
        ><a :href="'mailto:' + ownerStore.owner.Email_key3">{{
          ownerStore.owner.Email_key3
        }}</a></ion-col
      >
    </ion-row>
    <ion-row v-if="ownerStore.owner.Email != ''">
      <ion-col>Email: </ion-col>
      <ion-col
        ><a :href="'mailto:' + ownerStore.owner.Email">{{
          ownerStore.owner.Email
        }}</a></ion-col
      >
    </ion-row>
    <!-- <div expandable> -->
    <ion-label>Summary</ion-label>
    <ion-row class="ion-align-items-center">
      <ion-col>
        <ion-row>
          <img
            src="@/assets/img/property1.png"
            alt="No Of Properties"
            style="width: 30px"
          />
        </ion-row>
        <ion-row>
          <ion-label>{{ ownerStore.owner.NoOfProperties }}</ion-label>
        </ion-row>
        <ion-row>
          <ion-label>Properties</ion-label>
        </ion-row>
      </ion-col>
      <ion-col>
        <ion-row>
          <img
            src="@/assets/img/portfolio1.png"
            alt="Total Portfolio"
            style="width: 30px"
          />
        </ion-row>
        <ion-row>
          <ion-label>{{ ownerStore.owner.TotalPortfolio }}</ion-label>
        </ion-row>
        <ion-row>
          <ion-label>Portfolio Value</ion-label>
        </ion-row>
      </ion-col>
    </ion-row>
    <!-- TODO -->
    <ion-row class="ion-align-items-center">
      <BasicTableApp />
    </ion-row>
    <h1>PROFILE</h1>

    <ion-row class="ion-align-items-center">
      <ion-col>Contact ID:</ion-col>
      <ion-col>{{ ownerStore.owner.ContactID }}</ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center">
      <ion-col>Relationship: </ion-col>
      <ion-col>{{ ownerStore.owner.Relationship }}</ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center">
      <ion-col>Market Position: </ion-col>
      <ion-col>{{ ownerStore.owner.MarketPosition }}</ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center">
      <ion-col>Investment Range: </ion-col>
      <ion-col>{{ ownerStore.owner.InvestmentRange }}</ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center">
      <ion-col>Type: </ion-col>
      <ion-col>{{ ownerStore.owner.Type }}</ion-col>
    </ion-row>

    <ion-row class="ion-align-items-center">
      <ion-col>LAST RESULT: </ion-col>
      <ion-col>{{ ownerStore.owner.LastResult }}</ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center">
      <ion-col>NEXT ACTION: </ion-col>
      <ion-col>{{ ownerStore.owner.NextAction }}</ion-col>
    </ion-row>
  </div>
</template>


<script setup>
import router from "@/router";
import {
  IonContent,
  IonToolbar,
  IonButtons,
  IonButton,
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonLabel,
  IonTitle,
} from "@ionic/vue";

import BasicTableApp from "./portfoliotv.vue";

//for API call
import axios from "axios";
import { computed, onMounted } from "vue";
import { useOwnerStore, useSearchStore } from "@/state";
// import { search } from "ionicons/icons";

const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)

const ownerStore = useOwnerStore();
const searchStore = useSearchStore();
const hasData = computed(() => ownerStore.owner != {});
const FullName1 = computed(() =>
  (ownerStore.owner.FirstName1 + " " + ownerStore.owner.LastName1).trim()
);
const FullName2 = computed(() =>
  (ownerStore.owner.FirstName2 + " " + ownerStore.owner.LastName2).trim()
);
onMounted(() => {
  if (hasData.value) {
    if (Object.prototype.hasOwnProperty.call(ownerStore.owner, "id_contacts")) {
      getPortfolioRows(ownerStore.owner.db, ownerStore.owner.id_contacts);
    }
  }
});

const getPortfolioRows = async (db, contactid) => {
  var dbURL =
    "https://us-central1-terratonepull-c669d.cloudfunctions.net/PortfolioTreeView?database=" +
    db +
    "&contactID=" +
    contactid;

  await axios.get(proxyurl1 + dbURL).then(function (response) {
    console.log("portfolio:", response.data[0]);
    ownerStore.portfolio = response.data[0];
  });
};

const goToSearch2FN1 = () => {
  searchStore.strSearch = FullName1.value;
  router.push({ name: "owner-search" });
};

const goToSearch2FN2 = () => {
  searchStore.strSearch = FullName2.value;
  router.push({ name: "owner-search" });
};

const goToSearch2CY = () => {
  searchStore.strSearch = ownerStore.owner.Company;
  router.push({ name: "owner-search" });
};
</script>

<style scoped>
ion-page {
  background-color: #abc;
  height: 100%;
}
</style>
