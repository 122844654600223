// import fb from "../firebase"
// Import the EventBus we just created.
// import EventBus from '../config/event-bus';

import { db, db2 } from "../firebase";
import { 
    collection, getDocs, doc, setDoc, updateDoc, addDoc, deleteDoc, 
    onSnapshot, query, where, increment, Timestamp, getDoc, orderBy, limit 
} from "firebase/firestore";
import _, { map } from "lodash"
import axios from 'axios';
// handle page reload

import { defineStore } from 'pinia'
import { useAuthStore } from '../router/useAuthStore';
import { isPlatform } from '@ionic/vue';

var StartTime
StartTime=new Date().getTime()

export const globalStore = defineStore('global', {
  // arrow function recommended for full type inference
  state: () => {
    return {
      counter: 0,
      name: 'Eduardo',
      isAdmin: true,
    }
  },
})
const TRANSACTIONSLIMIT = 50
const proxyurl1 = "https://index.laurel.workers.dev/?"

export const useMapStore = defineStore('map', {
    // arrow function recommended for full type inference
    state: () => {
      return {
        geoHash: "",
        map: null,
        PID: "", 
        owner: null,
        property: null, 
        selectedArea: {},
        bookmarks: [],
        transactions:[],
        filteredTransactions:[],
        lonlat: [],
        isSheetModalOpen: false,
        selectedParcel: {},
        selectedParcel_id: "",
        selectedCentre:{}, 
        selectedAddress:""
      }
    },
    getters: {
        fabVerticalPos:(state) => state.isSheetModalOpen && isPlatform("mobile")?"center":"bottom",
        checkIsBookmarked: (state) => (selectedParcel_id) => state.bookmarks.find(bookmark=>bookmark.parcel_id==selectedParcel_id)?true:false,
        hasBookmark: (state) => state.bookmarks.length > 0,
        hasSelectedArea: (state) => !_.isEmpty(state.selectedArea), 
        transactionsCount: (state) => state.transactions.length > TRANSACTIONSLIMIT? TRANSACTIONSLIMIT: state.transactions.length,
        displayOwnerFullName: (state) => state.owner?.FirstName1 + " " + state.owner?.LastName1,
        displayAddressForModal: (state) => (isToBeConfirmed) => (isToBeConfirmed)? state.property?.Address + " (To be Confirmed)": state.property?.Address
    }, 
    actions: {
        onSheetModelClose() {
            this.isSheetModalOpen = false
            this.selectedArea = {}
        },
        async fetchBookmarks() {
            const authStore = useAuthStore()

            const querySnapshot = await getDocs(query(collection(db, "users", authStore.currentUser.uid, "bookmarks")));
            this.bookmarks = []
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                this.bookmarks.push({id:doc.id, ...doc.data()})
            });
        },
        async addBookmark(bookmark) {
            const authStore = useAuthStore()

            if(!this.bookmarks.find(stateBookmark => stateBookmark.parcel_id == bookmark.parcel_id)) {
                const docRef = await addDoc(collection(db, "users", authStore.currentUser.uid, "bookmarks"), bookmark);
                console.log("Document written with ID: ", docRef.id);
                this.bookmarks.push({id:docRef.id, ...bookmark})
            }
        },
        async deleteBookmark(parcel_id) {
            const authStore = useAuthStore()
            
            let index = this.bookmarks.findIndex(stateBookmark => stateBookmark.parcel_id == parcel_id)
            console.log('dbookmark', index)
            if(index != -1) {
                console.log('uid', authStore.currentUser.uid, 'bookmark id', this.bookmarks[index].id)
                const bookmarkRef = doc(db, "users", authStore.currentUser.uid, "bookmarks", this.bookmarks[index].id)
                await deleteDoc(bookmarkRef);
                this.bookmarks.splice(index, 1)
            }
        },
        async getParcelInfoByPID(iPID) {
            // var aOwner = {};
            // var aProperty = {};
            this.PID =iPID.substr(0, 3) + "-" + iPID.substr(3, 3) + "-" + iPID.substr(6)
            // var info = "";
            var dbURL ="https://2caz1ubxn0.execute-api.us-west-2.amazonaws.com/prod/parcel/byID/" + this.PID
            // let that=this
            await axios.get(proxyurl1 + dbURL).then((response) => {
              console.log("in GetParcelInfoByPID", response);
              if (response.data.statusCode == "200") {
                  let ownerObj = {}
                _.forEach(response.data.body.Info, (value, key) => {
                    // console.log("Info",key, value )
                  if (!value || value == "null") {
                    value = "";
                  }
                  ownerObj[key] = value;
                });


                this.owner = ownerObj
                // this.property = response.data.body
                let propertyObj = {}
                _.forEach(response.data.body, (value, key) => {
                    // console.log("body",key, value )
                  if (key != "ParcelPolygon" && key != "Info") {
                    if (!value || value == "null") {
                      value = "";
                    }
                    propertyObj[key] = value;
                  }
                });
                
                // this.property["pid"] = this.PID;
                this.property = {pid: this.PID, ...propertyObj}
              } else {
                this.property=null
                this.owner=null
              }
            })
        }, getRecentTransactions(clickedFeature) {
            this.transactions = []
            var innerFeatures = clickedFeature.get('features')
            console.log('innerFeatures', innerFeatures)
            for (let innerFeature of innerFeatures) {
              let index = innerFeature.get('index')
              this.transactions.push(this.filteredTransactions[index])
            }
        }
    }
})

const STATUS_LOADING = "LOADING";
const STATUS_FINISH = "FINISH"
const STATUS_ERROR = "ERROR"
const STATUS_IDLE = "IDLE"
export const useLoadingStore = defineStore('loading', {
    state: () => {
        return {
            loadingStatus: STATUS_IDLE,
            loadingMessage: ""
        }
    },
    getters: {
        isLoading: (state) => state.loadingStatus === STATUS_LOADING,
        isLoadingFinish: (state) => state.loadingStatus === STATUS_FINISH,
        isLoadingError: (state) => state.loadingStatus === STATUS_ERROR,
        isIdle: (state) => state.loadingStatus === STATUS_IDLE,
    },
    actions: {
        onLoading(msg) {
            this.message = msg
            this.loadingStatus = STATUS_LOADING
        },
        onLoadingFinish(msg) {
            this.message = msg
            this.loadingStatus = STATUS_FINISH
        },
        onLoadingError(err) {
            this.message = err
            this.loadingStatus = STATUS_ERROR
        },
    }
})
export const usePropertyStore = defineStore('property', {
    state: () => {
        return {
            property: {}
        }
      },
      getters: {
        AssessmentLand: (state) => {
            return state.property?.CurrAssessLand?.replace(/.(?=(?:.{3})+$)/g,"$&,")
        },
        AssessmentBuilding: (state) => state.property?.CurrAssessBuilding?.replace(/.(?=(?:.{3})+$)/g, "$&,")
      }, 
      actions: {
        
      }
})

export const useOwnerStore = defineStore('owner', {
    state: () => {
        return {
            owner: {},
            portfolio: []
        }
      },
      getters: {
          getPortfolioTableData: (state) => {
            return state.portfolio.map(({LocationOriginal, CurrAssessTotal, LO}) => ([LocationOriginal, CurrAssessTotal, LO]))
          }
      }, 
      actions: {
        
      }
})

export const useSearchStore = defineStore('search', {
    state: () => {
        return {
            strSearch: "",
            contactSearchResult: [],
            titleSearchResult: [],
            companySearchResult: [],
            // items4: [],
            // for using pdf.js pre-build web viewer
            name: "Test_PDF.pdf", //change which pdf file loads
            path: "lib/web/viewer.html", //path of the PDF.js viewer.html

            showDialog: false,
            showContacts:true,
            showCompanies:true,
            showTitles:true,
        }
      },
      getters: {
        contactSearchString: (state) => state.strSearch,
        titleSearchString: (state) => state.strSearch.toUpperCase(),
        companySearchString: (state) => state.strSearch.toUpperCase(),
      }, 
      actions: {
        async searchResult () {
            if(this.showContacts) this.contactSearch()
            if(this.showCompanies) this.companySearch()
            if(this.showTitles) this.titleSearch()
        },
        async contactSearch() {
            console.log("SearchString:", this.strSearch);
            // if (this.strSearch) {
            var contactArray = [];
            const search_criteria = "&criteria=name,company";
            //const search_criteria="&criteria=name,company,telephone,contact-address,property-address,city,zip"
            //https://hv3ziiajak.execute-api.us-west-2.amazonaws.com/Beta/contact/Amy C
            var dbURL = "https://us-central1-terratonepull-c669d.cloudfunctions.net/contacts/linkproperty/search?text=" +
                this.contactSearchString + search_criteria;
            var contactlist = [];
    
            const response = await axios.get(dbURL);
            if (response.status == 200) {
                contactlist = response.data;
                contactlist.forEach(function(contact) {
                    contactArray.push(contact);
                });
            }
            
            this.contactSearchResult = contactArray;
        },
        async titleSearch(){
            this.titleSearchResult = [];
            console.log("titleSearch", this.titleSearchString);
            var strlength = this.titleSearchString.length;
            var strFrontCode = this.titleSearchString.slice(0, strlength - 1);
            var strEndCode = this.titleSearchString.slice(
            strlength - 1,
            this.titleSearchString.length
            );

            var startcode = this.titleSearchString;
            var endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
            var titleArray = [];
            console.log("titleSearch2", startcode, endcode)

            const querySnapshot = await getDocs(query(
                collection(db2, "titlesearch"), 
                where("filename", ">=", startcode), 
                where("filename", "<", endcode), 
                orderBy("filename"),
                limit(10)));

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                this.titleSearchResult.push({id:doc.id, ...doc.data()})
            });
        },
        async companySearch() {
            this.companySearchResult = [];
            console.log("companySearch", this.companySearchString);
            var strlength = this.companySearchString.length;
            var strFrontCode = this.companySearchString.slice(0, strlength - 1);
            var strEndCode = this.companySearchString.slice(
              strlength - 1,
              this.strSearch.length
            );
    
            var startcode = this.companySearchString;
            var endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
            var companyArray = [];

            console.log("companySearch2", startcode, endcode)
            const querySnapshot = await getDocs(query(
                collection(db2, "corpsearch"),               
                where("filename", ">=", startcode), 
                where("filename", "<", endcode), 
                orderBy("filename"),  
                limit(10)));

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                this.companySearchResult.push({id:doc.id, ...doc.data()})
            });
        }
      }
})



export const useSocialStore = defineStore('social', {
    // arrow function recommended for full type inference
    state: () => {
      return {
        posts: [],
        newPostCount: 0, 
        comments: [],
        hiddenPosts: [],
        likedPosts: [], //TODO: sets()
        likedComments: [], //TODO: sets()
        likes:[],
        commentLikes:[],
        selectedPost: {},
        strPostSearch: "",
        filterPostPid: "",
        filterPostAddress: "",
        filterByCreator: false,
        filterGeoHash: ""
      }
    },
    getters: {
        getPostByPostId: (state) => (postId) => {
            let post = state.posts.filter((post) => post.id === postId)
            if(post.length > 0) {
                return post
            } else {
                return state.fetchPostbyPostId(postId)
            }
        },
        canPostBeDeleted: (state) => (postId) => {
            const authStore = useAuthStore()
            const postToDelete = state.posts.filter(post => post.id === postId && post.userId === authStore.currentUser.uid)
            return postToDelete.length > 0;

        }, 
        filteredPosts: (state) => {
            const authStore = useAuthStore()
            let regex = new RegExp(state.strPostSearch, 'gi')
            let filteredPosts = []
            console.log("filteredPosts", state.filterPostPid )
            // if (state.filterPostPid == "") {
                filteredPosts = state.posts.filter((post) => {
                    if (!state.filterByCreator) {
                        return post.content.match(regex) && !post.deleted;
                    } else {
                        return (
                            post.userId == authStore.currentUser.uid &&
                            post.content.match(regex) &&
                            !post.deleted
                        );
                    }
                });
            return _.orderBy(filteredPosts, ['createdOn'], ['desc']);
        },
        filteredPostsByPid: (state) => {
            return state.posts.filter((post) => {
                // if (Object.prototype.hasOwnProperty.call(post, "propinfo")) {
                if(post.propinfo) {
                    return post.propinfo.pid == state.filterPostPid && !post.deleted
                } else {
                    return false && !post.deleted
                }
            });
        },
        filteredPostsByGeoHash: (state) => {
            return state.posts.filter((post) => {
                if(post.propinfo) {
                    return post.propinfo.geoHash == state.filterGeoHash && !post.deleted
                } else {
                    return false && !post.deleted
                }
            });
        },
        findLikedPostsIndex: (state) => (postId) => {
            return state.likedPosts.findIndex(likedPost => likedPost.postId == postId)
        },
        checkIsPostLiked: (state) => (postId) => {
            return (state.findLikedPostsIndex(postId) != -1)
        },
        findLikedCommentsIndex: (state) => (commentId) => {
            return state.likedComments.findIndex(likedComment => likedComment.commentId == commentId)
        },
        checkIsCommentLiked: (state) => (commentId) => {
            return (state.findLikedCommentsIndex(commentId) != -1)
        },
        checkIsPostHidden: (state) => {
            return true
        },
    }, 
    actions: {
        async fetchComments(postId) {
            const loadingStore = useLoadingStore()
            loadingStore.onLoading()
            console.log("fetchComments", postId, loadingStore.isLoading)
            const querySnapshot = await getDocs(query(collection(db, "comments"), where("postId", "==", postId)));
            let comments = []
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                comments.push({id:doc.id, ...doc.data()})
            });
            this.comments = _.orderBy(comments, ['createdOn'], ['desc']);

            this.fetchCommentsLikesByUser()
            loadingStore.onLoadingFinish()
            console.log("finsih loading", loadingStore.isLoadingFinish)

        },
        async addComment(postId, comment) {
            const loadingStore = useLoadingStore()
            const authStore = useAuthStore()
            loadingStore.onLoading("Adding Comment")
            try {
                const commentObj = {
                    createdOn: Timestamp.now(),
                    content: comment.content,
                    postId: postId,
                    userId: comment.userId,
                    userName: authStore.currentUser.displayName,
                    userPhotoURL: authStore.currentUser.photoURL,
                    likes: 0
                }
    
                const docRef = await addDoc(collection(db, "comments"), commentObj);
                await updateDoc(doc(db, "posts", postId), { comments: increment(1) }) 
                console.log("Document written with ID: ", docRef.id);
                console.log(commentObj)
                this.comments.push({id:docRef.id, ...commentObj});
                this.comments = _.orderBy(this.comments, ['createdOn'], ['desc']);
                comment.content = ""
                loadingStore.onLoadingFinish()
            } catch (ex) {
                console.log("addcomment error", ex)
                loadingStore.onLoadingError(ex)
            }
        },
        async fetchPostsLikesByUser() {
            const loadingStore = useLoadingStore()
            const authStore = useAuthStore()
            loadingStore.onLoading()
            const q = query(collection(db, "likes"), where("userId", "==", authStore.currentUser.uid))

            const unsubscribe = onSnapshot(q, (snapshot)=>{
                let postslikesArray = []
                snapshot.forEach(doc => {
                    console.log("snapshot.forEach", doc)
                    postslikesArray.push({id:doc.id, ...doc.data()}) 
                })

                this.likedPosts = postslikesArray;
            })

            loadingStore.onLoadingFinish()
            console.log("finsih loading", loadingStore.isLoadingFinish)
        },

        async fetchCommentsLikesByUser() {
            const loadingStore = useLoadingStore()
            const authStore = useAuthStore()
            loadingStore.onLoading()
            const q = query(collection(db, "commentlikes"), where("userId", "==", authStore.currentUser.uid))

            const unsubscribe = onSnapshot(q, (snapshot)=>{
                let commentslikesArray = []
                snapshot.forEach(doc => {
                    console.log("snapshot.forEach", doc)
                    commentslikesArray.push({id:doc.id, ...doc.data()}) 
                })

                console.log("fetchCommentsLikesByUser", commentslikesArray)
                this.likedComments = commentslikesArray;
            })

            loadingStore.onLoadingFinish()
        },
        async toggleLikePost(postId) {
            const authStore = useAuthStore()
            let userId = authStore.currentUser.uid
            let docId = `${userId}_${postId}`

            
            let index = this.findLikedPostsIndex(postId)
            if(index != -1) {
                console.log("unlike", postId, docId, userId)
                await deleteDoc(doc(db, "likes", docId))
                await updateDoc(doc(db, "posts", postId), { likes: increment(-1) }) 
                this.likedPosts.splice(index, 1)
            } else {
                console.log("like", postId, docId, userId)

                //Uncaught (in promise) FirebaseError: Invalid collection reference. Collection references must have an odd number of segments, but likes/H0Z4ClvHjkfw8DMb2jjFQ1RKG7U2_4PeBLsCBKVTCQs6kE4vX has 2.
                await setDoc(doc(db, "likes", docId), {
                    postId: postId,
                    userId: userId
                })
                await updateDoc(doc(db, "posts", postId), { likes: increment(1) }) 
                this.likedPosts.push(postId)
            }
            
        },
        async toggleLikeComment(commentId) {
            const authStore = useAuthStore()
            let userId = authStore.currentUser.uid

            let docId = `${userId}_${commentId}`

            let index = this.findLikedCommentsIndex(commentId)
            if(index != -1) {
                await deleteDoc(doc(db, "commentlikes", docId))
                await updateDoc(doc(db, "comments", commentId), { likes: increment(-1) }) 
                this.likedComments.splice(index, 1)
            } else {
                await setDoc(doc(db, "commentlikes", docId), {
                    commentId: commentId,
                    userId: userId,
                })
                await updateDoc(doc(db, "comments", commentId), { likes: increment(1) }) 
                this.likedComments.push(commentId)

                console.log("toggleLikeComment", this.likedComments)
            }
        },
        async fetchPosts() {
            console.log("in fetchPosts");
            const loadingStore = useLoadingStore()
            loadingStore.onLoading()

            const q = query(collection(db, "posts"))

            const unsubscribe = onSnapshot(q, (snapshot)=>{
                let newPostCount
                snapshot.docChanges().forEach(change => {
                    newPostCount = 0
                    if (change.type==='added'){
                        if (!change.doc.metadata.hasPendingWrites) {
                            newPostCount++;
                        }
                    }
                    
                    console.log("new post count",newPostCount)
                    console.log("StartTime in post collection",StartTime)
                })

                var firstDocChangeAddTime=snapshot.docChanges()[0].doc.data().createdOn.toDate().getTime()
                console.log("CreateTime 1st DocChanges",firstDocChangeAddTime)
            
                if (StartTime <= firstDocChangeAddTime) {
                    this.newPostCount = newPostCount;
                    // store.commit("setnewpostcount",inewpostcount)
                    // EventBus.$emit('newpostcount',inewpostcount)
                }    
            
                // check if created by currentUser
                //let createdByCurrentUser;
                //let changes;
                console.log("querySnapshot", snapshot.size);
                console.log(snapshot.docChanges().length)
                let postsArray = []
                snapshot.forEach(doc => {
                    console.log("snapshot.forEach", doc)
                    postsArray.push({id:doc.id, ...doc.data()}) 
                })

                this.posts = postsArray;

                this.fetchPostsLikesByUser()
            })

            loadingStore.onLoadingFinish()
        },

        async fetchPostbyPostId(postId) {
            console.log("in fetchPost: id "+ postId);
            const loadingStore = useLoadingStore()
            loadingStore.onLoading()

            const docRef = doc(db, "posts", postId)
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                loadingStore.onLoadingFinish()
                return {id: postId, ...docSnap.data()}
            } else {
                loadingStore.onLoadingError("no post found")
                console.log("no post found for postId: "+postId)
                return {}
            }
        }, 
        async addPost(post) {
            const mapStore = useMapStore()
            const authStore = useAuthStore();
            let pInfo = {pid: mapStore.PID, Address: mapStore.selectedAddress}

            if(mapStore.property) {
                pInfo = mapStore.property
            }
            if (mapStore.geoHash) {
                pInfo = {geoHash: mapStore.geoHash, ...pInfo};
            }
            // console.log("selectedCentre", mapStore.selectedCentre, mapStore.selectedCentre?"selectedCentre notnull":"selectedCentre null")
            // console.log("pInfo", pInfo)

            const isTaggedPost = post.tagParcel;
            let postToAdd = {
                createdOn: Timestamp.now(),
                deleted:false,
                title: post.title,
                content: post.content,
                userId: authStore.currentUser.uid,
                userName: authStore.currentUser.displayName,
                userPhotoURL: authStore.currentUser.photoURL,
                comments: 0,
                likes: 0,
                tagParcel: isTaggedPost,
                ownerinfo: isTaggedPost? mapStore.owner : null,
                propinfo: isTaggedPost? pInfo : null,
                centre: isTaggedPost? mapStore.selectedCentre.flatCoordinates: null
            }
                        
            const docRef = await addDoc(collection(db, "posts"), postToAdd);
            // console.log("Document written with ID: ", docRef.id);
            this.posts.push({id: docRef.id, ...post})
            
            post.content = "";
            post.title = "";

            return true;
        },
        async softDeletePost(postId) {
            console.log('softDeletePost', postId)
            if(this.canPostBeDeleted(postId)){
                let index = this.posts.findIndex(statePost => statePost.id == postId)

                console.log('can delete', postId, index)
                await updateDoc(doc(db, "posts", postId), {
                    deleted: true
                });
                // this.posts[index].deleted = true;
            }
        },
        async fetchLikedUserList(postId) {
            const loadingStore = useLoadingStore()
            loadingStore.onLoading()
            const querySnapshot = await getDocs(query(collection(db, "likes"), where("postId", "==", postId)))
            this.likes = []
            querySnapshot.forEach(async (docData) => {
                const userRef = doc(db, "users", docData.data().userId)
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    loadingStore.onLoadingFinish()
                    this.likes.push(docSnap.data())
                } else {
                    loadingStore.onLoadingError("no user found")
                    console.log("no user found for postId likes: "+postId)
                }
            });

            loadingStore.onLoadingFinish()
            console.log("finsih loading", loadingStore.isLoadingFinish)
        },
        async fetchCommentLikedUserList(commentId) {
            const loadingStore = useLoadingStore()
            loadingStore.onLoading()

            const querySnapshot = await getDocs(query(collection(db, "commentlikes"), where("commentId", "==", commentId)))
            this.commentLikes = []
            console.log(querySnapshot.size)
            querySnapshot.forEach(async (docData) => {
                const userRef = doc(db, "users", docData.data().userId)
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    loadingStore.onLoadingFinish()
                    this.commentLikes.push(docSnap.data())
                } else {
                    loadingStore.onLoadingError("no user found")
                    console.log("no user found for commentId likes: "+commentId)
                }
            });

            loadingStore.onLoadingFinish()
            console.log("finsih loading", loadingStore.isLoadingFinish)
        },
    }
})


