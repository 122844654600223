<template>
  <ion-page>
    <ion-header>
      <ion-toolbar v-if="props.backButton">
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <!-- <ion-buttons slot="primary">
          <ion-button>
            <ion-icon slot="icon-only" :icon="search"></ion-icon>
          </ion-button>
        </ion-buttons> -->
        <ion-searchbar
          placeholder="Search Contacts, Companies(min 3 letters) or Titles(PID)"
          :value="searchStore.strSearch"
          @ionChange="searchbarInput"
          @ionClear="searchResults = []"
          debounce="500"
        ></ion-searchbar>
      </ion-toolbar>
      <ion-toolbar
        style="
          display: block;
          z-index: -1;
          overflow-x: scroll;
          white-space: nowrap;
        "
      >
        <ion-chip @click="toggleContactSearch()">
          <ion-icon v-if="isContactSearch" :icon="checkmarkCircle"></ion-icon>
          <ion-icon v-else :icon="ellipseOutline"></ion-icon>
          <ion-label>Contacts</ion-label>
        </ion-chip>
        <ion-chip @click="toggleCompanySearch()">
          <ion-icon v-if="isCompanySearch" :icon="checkmarkCircle"></ion-icon>
          <ion-icon v-else :icon="ellipseOutline"></ion-icon>
          <ion-label>Companies</ion-label>
        </ion-chip>
        <ion-chip @click="toggleTitleSearch()">
          <ion-icon v-if="isTitleSearch" :icon="checkmarkCircle"></ion-icon>
          <ion-icon v-else :icon="ellipseOutline"></ion-icon>
          <ion-label>Titles</ion-label>
        </ion-chip>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div v-show="searchStore.showContacts" id="contactsCard">
        <ion-item-divider>Contacts</ion-item-divider>
        <ion-item v-if="searchStore.contactSearchResult.length == 0"
          >No results</ion-item
        >
        <ion-item
          v-for="(item, index) in searchStore.contactSearchResult"
          :key="'contacts_' + index"
        >
          <ion-label>
            <ion-text color="dark"
              ><p>{{ item.FirstName1 }} {{ item.LastName1 }}</p></ion-text
            >
            <p>{{ item.Company }}</p>
            <p v-if="item.Telephone1">Work: {{ item.Telephone1 }}</p>
            <p v-if="item.Telephone4">Home: {{ item.Telephone4 }}</p>
            <p v-if="item.Telephone3">Mobile: {{ item.Telephone3 }}</p>
            <p v-if="item.Email">Email: {{ item.Email }}</p>
            <p>Property({{ item.PropertyList.length }}):</p>
            <div
              v-for="property in item.PropertyList"
              :key="property.taxrecord_id"
            >
              <p>- {{ property.LocationOriginal }}</p>
            </div>
          </ion-label>
        </ion-item>
      </div>
      <div v-show="searchStore.showCompanies" id="companiesCard">
        <ion-item-divider>Companies</ion-item-divider>
        <ion-item v-if="searchStore.companySearchResult.length == 0"
          >No results</ion-item
        >
        <ion-item v-for="item in searchStore.companySearchResult" :key="item">
          <ion-label @click="openFile(item.filename, 'company')">
            <ion-text color="dark">
              <p v-if="item.filename">{{ item.filename.slice(0, -4) }}</p>
            </ion-text>
          </ion-label>
        </ion-item>
      </div>
      <div v-show="searchStore.showTitles" id="titlesCard">
        <ion-item-divider>Titles</ion-item-divider>
        <ion-item v-if="searchStore.titleSearchResult.length == 0"
          >No results</ion-item
        >
        <ion-item v-for="item in searchStore.titleSearchResult" :key="item">
          <ion-label @click="openFile(item.filename, 'title')">
            <ion-text color="dark">
              <p v-if="item.filename">
                {{ item.filename.slice(0, -4) }}
              </p>
            </ion-text>
          </ion-label>
        </ion-item>
      </div>

      <ion-modal :is-open="isOpenPDFModal">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="end">
              <ion-button @click="setOpenPDFModal(false)">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <PDFJSViewer
            :path="`${searchStore.path}`"
            :name="`${openedFilename}`"
          />
        </ion-content>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>
<script setup>
import {
  IonPage,
  IonModal,
  IonContent,
  IonCheckbox,
  // IonThumbnail,
  // IonAvatar,
  // IonCheckbox,
  // IonInput,
  IonItem,
  IonItemDivider,
  // IonItemOption,
  // IonItemOptions,
  // IonItemSliding,
  IonList,
  IonLabel,
  // IonRadio,
  // IonToggle,
  // IonFab,
  // IonFabList,
  // IonFabButton,
  IonText,
  IonIcon,
  IonCard,
  IonCardTitle,
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonSearchbar,
  IonChip,
  IonHeader,
  IonBackButton,
  // onIonViewWillEnter, onIonViewDidEnter, onIonViewWillLeave, onIonViewDidLeave, modalController, alertController
} from "@ionic/vue";

import { search, checkmarkCircle, ellipseOutline } from "ionicons/icons";

import PDFJSViewer from "./PDFJSViewer.vue";
import { useSearchStore } from "../../state";
import { useRouter } from "vue-router";
import { ref, defineProps } from "vue";
import { firebase2 } from "@/firebase";

firebase2
  .auth()
  .signInAnonymously()
  .then(() => {
    console.log("Sign in Anonymously to storage project ok");
  })
  .catch((error) => {
    console.log("Error sign in anonymously", error.message);
  });

const router = useRouter();

const props = defineProps(["backButton"]);

const searchStore = useSearchStore();
const proxyurl = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)

const searchResults = ref([]);
const showSearchResults = ref(false);

const isContactSearch = ref(true);
const isCompanySearch = ref(true);
const isTitleSearch = ref(true);

const searchbarInput = (ev) => {
  showSearchResults.value = true;

  if (ev.target.value.length >= 3) {
    searchStore.strSearch = ev.target.value;
    searchStore.searchResult();
  } else {
    showSearchResults.value = false;
    searchResults.value = [];
  }
};

const toggleContactSearch = () => {
  searchStore.showContacts = !searchStore.showContacts;
  isContactSearch.value = !isContactSearch.value;
};

const toggleCompanySearch = () => {
  searchStore.showCompanies = !searchStore.showCompanies;
  isCompanySearch.value = !isCompanySearch.value;
};

const toggleTitleSearch = () => {
  searchStore.showTitles = !searchStore.showTitles;
  isTitleSearch.value = !isTitleSearch.value;
};

import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { storage2 } from "@/firebase";

const isOpenPDFModal = ref(false);
const openedFilename = ref("");
const openedFileURL = ref("");

const setOpenPDFModal = (isOpen) => {
  isOpenPDFModal.value = isOpen;
};

const openFile = (filename, type) => {
  openedFilename.value = filename;

  let prefix = "";
  if (type == "company") {
    prefix = "corporatesearch/";
  } else if (type == "title") {
    prefix = "titlesearch/";
  }
  var pdf_name = prefix + filename;
  console.log(" pdf_name: " + pdf_name);

  const pathReference = storageRef(storage2, pdf_name);

  // Get the download URL
  getDownloadURL(pathReference).then((url) => {
    console.log("pdf URL: " + url);
    isOpenPDFModal.value = true;
    openedFileURL.value = url;
    openedFilename.value = encodeURIComponent(url);
    console.log(openedFilename.value);
    //var pdfurl = './TITLE-CA6771686-PID-004-101-235.pdf';
  });
};
</script>
<style scoped>
#txtCoorpListSearch {
  background-size: 20px;
  background-position: 4px 10px;
  background-repeat: no-repeat;
  width: 97%;
  padding: 0px 5px 0px 30px;
  font-size: 16px;
  border: 1px solid #ddd;
  margin-bottom: 0px;
  margin-left: 5px;
  display: block;
  line-height: 40px;
}

#txtCoorpListSearch::placeholder {
  font-size: 12px;
}

.button-bar__button {
  background-color: royalblue;
  color: white;
}
</style>
