<template>
  <!-- <ion-card
    v-show="
      Object.prototype.hasOwnProperty.call(propertyStore.property, 'Address')
    "
  >
    <ion-card-header>
      <ion-card-title> {{ propertyStore.property?.Address }}</ion-card-title>
      <ion-card-subtitle>{{
        propertyStore.property?.Zoning
      }}</ion-card-subtitle>
      <ion-label v-if="propertyStore.property.Pid" @click="goToSearch2TL">
        <a>Search by Title(PID) </a>
      </ion-label>
    </ion-card-header>
    <ion-card-content>
      <ion-grid>
        <ion-row class="ion-justify-content-start">
          <ion-label v-if="propertyStore.property.LO" @click="goToSearch2CY">
            <a>{{ propertyStore.property?.LO }}</a>
          </ion-label>
        </ion-row>
        <ion-row class="ion-justify-content-start">
          <ion-col>Lot Size: </ion-col>
          <ion-col>{{ propertyStore.property.LotSize }}</ion-col>
        </ion-row>
        <ion-row
          class="ion-justify-content-start"
          v-if="propertyStore.property?.BuildingStoreys != ''"
        >
          <ion-col>Building Storeys: </ion-col>
          <ion-col>{{ propertyStore.property.BuildingStoreys }}</ion-col>
        </ion-row>
        <ion-row
          class="ion-justify-content-start"
          v-if="propertyStore.property?.NoOfUnits != ''"
        >
          <ion-col>No Of Units: </ion-col>
          <ion-col>{{ propertyStore.property.NoOfUnits }}</ion-col>
        </ion-row>
        <ion-row
          class="ion-justify-content-start"
          v-if="propertyStore.property?.GrossTax != ''"
        >
          <ion-col>Gross Tax: </ion-col>
          <ion-col>{{ propertyStore.property.GrossTax }}</ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-start">
          <ion-col>Assessment (year): </ion-col>
          <ion-col
            >{{ propertyStore.property.AssessmentTotalValue }} ({{
              propertyStore.property.AssessmentYear
            }})</ion-col
          >
        </ion-row>
        <ion-row class="ion-justify-content-start">
          <ion-col>Land: </ion-col>
          <ion-col>{{ propertyStore.AssessmentLand }}</ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-start">
          <ion-col>Buildings: </ion-col>
          <ion-col>{{ propertyStore.AssessmentBuilding }}</ion-col>
        </ion-row>
      </ion-grid>
      <ion-button @click="go2propPosts">Tagged Posts</ion-button>
    </ion-card-content>
  </ion-card> -->
  <div
    v-show="
      Object.prototype.hasOwnProperty.call(propertyStore.property, 'Address')
    "
  >
    <h1>{{ propertyStore.property?.Address }}</h1>
    <h2>{{ propertyStore.property?.Zoning }}</h2>
    <h3 v-if="propertyStore.property.Pid" @click="goToSearch2TL">
      <a>Search by Title(PID) </a>
    </h3>

    <ion-grid>
      <ion-row class="ion-justify-content-start">
        <ion-label v-if="propertyStore.property.LO" @click="goToSearch2CY">
          <a>{{ propertyStore.property?.LO }}</a>
        </ion-label>
      </ion-row>
      <ion-row class="ion-justify-content-start">
        <ion-col>Lot Size: </ion-col>
        <ion-col>{{ propertyStore.property.LotSize }}</ion-col>
      </ion-row>
      <ion-row
        class="ion-justify-content-start"
        v-if="propertyStore.property?.BuildingStoreys != ''"
      >
        <ion-col>Building Storeys: </ion-col>
        <ion-col>{{ propertyStore.property.BuildingStoreys }}</ion-col>
      </ion-row>
      <ion-row
        class="ion-justify-content-start"
        v-if="propertyStore.property?.NoOfUnits != ''"
      >
        <ion-col>No Of Units: </ion-col>
        <ion-col>{{ propertyStore.property.NoOfUnits }}</ion-col>
      </ion-row>
      <ion-row
        class="ion-justify-content-start"
        v-if="propertyStore.property?.GrossTax != ''"
      >
        <ion-col>Gross Tax: </ion-col>
        <ion-col>{{ propertyStore.property.GrossTax }}</ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-start">
        <ion-col>Assessment (year): </ion-col>
        <ion-col
          >{{ propertyStore.property.AssessmentTotalValue }} ({{
            propertyStore.property.AssessmentYear
          }})</ion-col
        >
      </ion-row>
      <ion-row class="ion-justify-content-start">
        <ion-col>Land: </ion-col>
        <ion-col>{{ propertyStore.AssessmentLand }}</ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-start">
        <ion-col>Buildings: </ion-col>
        <ion-col>{{ propertyStore.AssessmentBuilding }}</ion-col>
      </ion-row>
    </ion-grid>
    <!-- <ion-button @click="go2propPosts">Tagged Posts</ion-button> -->
  </div>
</template>
<script setup>
// import { onMounted, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useSocialStore, usePropertyStore, useSearchStore } from "@/state";
import {
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonGrid,
  IonCol,
  IonRow,
  IonLabel,
  IonButton,
} from "@ionic/vue";
import { search } from "ionicons/icons";

const router = useRouter();
const socialStore = useSocialStore();
const searchStore = useSearchStore();

const propertyStore = usePropertyStore();

const go2propPosts = () => {
  socialStore.filterPostAddress = propertyStore.property.Address;
  socialStore.filterPostPid = propertyStore.property.Pid;

  router.push({ name: "post" });
};

const goToSearch2TL = () => {
  searchStore.strSearch = propertyStore.property.Pid;
  router.push({ name: "property-search" });
};
const goToSearch2CY = () => {
  searchStore.strSearch = propertyStore.property.LO;
  router.push({ name: "property-search" });
};
</script>
<style scoped>
ion-page {
  background-color: #777;
  height: 100%;
}
</style>
