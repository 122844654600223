<template>
  <ion-list>
    <Post :post="socialStore.selectedPost" />
  </ion-list>
  <ion-list v-if="socialStore.comments.length">
    <ion-item
      lines="none"
      v-for="comment in socialStore.comments"
      :key="comment.id"
    >
      <ion-avatar slot="start" style="width: 30px; height: 30px">
        <img
          :src="
            comment.userPhotoURL
              ? comment.userPhotoURL
              : 'http://ui-avatars.com/api/?rounded=true&name=' +
                comment.userName
          "
        />
      </ion-avatar>
      <ion-label>
        <p>
          <ion-text
            class="ion-padding-end"
            color="dark"
            @click="oneClick(fullPost.id)"
          >
            {{ comment.userName }}
          </ion-text>
          <ion-text color="dark">
            {{ comment.content }}
          </ion-text>
        </p>
        <p>
          <ion-text
            @click="displayUserCommentLikesModal(comment.id)"
            class="ion-padding-end"
            >{{ countDisplay(comment.likes, "like") }}</ion-text
          >
          <ion-text>{{ formatDate(comment?.createdOn) }} </ion-text>
        </p>
      </ion-label>
      <ion-note
        slot="end"
        @click="toggleCommentLikeUnLike(comment.id, comment.likes)"
      >
        <ion-icon
          v-if="socialStore.checkIsCommentLiked(comment.id)"
          :icon="heart"
        ></ion-icon>
        <ion-icon v-else :icon="heartOutline"></ion-icon>
      </ion-note>
    </ion-item>
  </ion-list>
  <div v-if="loadingStore.isLoading">
    {{ loadingStore.message }}
  </div>
</template>

<script setup>
import { reactive, computed, onMounted, watch, ref, defineEmits } from "vue";
import {
  heart,
  heartOutline,
  mapOutline,
  copyOutline,
  chatbubblesOutline,
} from "ionicons/icons";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonAvatar,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonListHeader,
  IonCheckbox,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonLabel,
  IonRadio,
  IonToggle,
  IonPage,
  IonNote,
  IonText,
  IonBackButton,
  IonModal,
  IonImg,
  IonButton,
} from "@ionic/vue";
import { useSocialStore, useLoadingStore } from "../../state";
import moment from "moment";
import { useRouter, useRoute } from "vue-router";
import _ from "lodash";

import { Timestamp } from "@firebase/firestore";

import CreateComment from "./create-comment.vue";
import Post from "./post.vue";

const route = useRoute();
const socialStore = useSocialStore();
const loadingStore = useLoadingStore();

const emit = defineEmits(["displayUserCommentLikesModal"]);

onMounted(async () => {
  if (_.isEmpty(socialStore.selectedPost) && route.params.postId) {
    socialStore.selectedPost = await socialStore.getPostByPostId(
      route.params.postId
    );
  }

  if (socialStore.selectedPost.id) {
    socialStore.fetchComments(socialStore.selectedPost.id);
  }
});

const toggleCommentLikeUnLike = (commentId, commentLikes) => {
  socialStore.toggleLikeComment(commentId);
};

const countDisplay = computed(() => (counts, strDisplay) => {
  console.log(counts, strDisplay);
  if (!counts || counts == 0) {
    return `0 ${strDisplay}`;
  } else if (counts > 1) {
    return `${counts} ${strDisplay}s`;
  } else {
    return `${counts} ${strDisplay}`;
  }
});

const formatDate = computed(() => (val) => {
  try {
    let date = val.toDate();

    return moment(date).fromNow();
  } catch (err) {
    console.log("formatDate Error:", err);
    return "-";
  }
});

const isLikesModalOpen = ref(false);
const displayUserCommentLikesModal = (commentId) => {
  isLikesModalOpen.value = true;
  socialStore.fetchCommentLikedUserList(commentId);

  emit("displayUserCommentLikesModal");
};
</script>

<style scoped>
</style>

